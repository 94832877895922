<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    tile
    max-width="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-subheader
        v-bind="attrs"
        v-on="on"
        label
        class="font-weight-bold clickable black--text px-0"
        :style="{ 'font-size': '0.75rem' }"
      >
        {{ header.text }}
        <HeaderFilterIconAcitve v-if="isHeaderActive" />
        <HeaderFilterIcon v-else />
      </v-subheader>
    </template>

    <v-card tile>
      <v-card-text>
        <v-row dense>
          <v-col
            cols="12"
            :class="{
              'font-weight-bold': isBoldFont(0),
            }"
          >
            <span class="clickable black--text" @click="updateSorting(0)">
              Sort from A-Z
            </span>
          </v-col>
          <v-col
            class="clickable black--text"
            :class="{
              'font-weight-bold': isBoldFont(1),
            }"
          >
            <span class="clickable black--text" @click="updateSorting(1)">
              Sort from Z-A
            </span>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              dense
              hide-details
              outlined
              prepend-inner-icon="mdi-magnify"
              @input="reload = !reload"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="searchedItems.length > 0 && itemsLoaded">
            <v-row align="center">
              <v-col class="mb-2">
                <v-checkbox
                  v-model="selectAll"
                  dense
                  hide-details
                  class="mt-0 ml-1"
                  :label="selectAllText"
                  :indeterminate="
                    selected.length > 0 &&
                    selected.length < searchedItems.length
                  "
                  color="msaBlue"
                  @change="toggleSelectAll"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-virtual-scroll
              :items="searchedItems"
              :height="280"
              :item-height="40"
              :key="reload"
            >
              <template v-slot:default="{ item }">
                <v-row no-gutters align="center">
                  <v-col class="shrink">
                    <v-checkbox
                      v-model="selected"
                      :value="item[itemValue]"
                      dense
                      hide-details
                      class="mt-0 pt-0 ml-1"
                      @change="checkSelectAll"
                    >
                    </v-checkbox>
                  </v-col>

                  <v-col class="text-truncate" :title="item[itemText]">
                    {{ item[itemText] }}
                  </v-col>
                </v-row>
              </template>
            </v-virtual-scroll>
            <v-row>
              <v-col>
                <v-btn
                  text
                  small
                  color="msaBlue"
                  @click="resetFilter()"
                  :disabled="
                    filterValue.length == 0 &&
                    !updateSort &&
                    !header.isSortingBy
                  "
                >
                  r <span class="text-lowercase">eset filter</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-else-if="searchedItems.length == 0 && itemsLoaded">
            <v-sheet height="280">
              <div class="text-center">No results found</div>
            </v-sheet>
          </v-col>
          <v-col v-if="!itemsLoaded" align="center">
            <v-sheet height="280">
              <v-progress-circular
                :size="50"
                color="msaBlue"
                indeterminate
              ></v-progress-circular>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import HeaderFilterIcon from '@/components/HeaderFilterIcon.vue';
import HeaderFilterIconAcitve from '@/components/HeaderFilterIconAcitve.vue';
export default {
  name: 'CompanyFormFilterableHeader',
  components: {
    HeaderFilterIcon,
    HeaderFilterIconAcitve,
  },
  props: {
    filterValue: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Object,
    },
    // item text key
    itemText: {
      type: String,
    },
    // item value key
    itemValue: {
      type: String,
    },
  },
  computed: {
    searchedItems() {
      return this.items.filter((item) => {
        // get rid of all special characters and spaces
        const plainText = item[this.itemText].replace(/[^a-zA-Z0-9]/g, '');
        const searchPlainText = this.search.replace(/[^a-zA-Z0-9]/g, '');
        return plainText.toLowerCase().includes(searchPlainText.toLowerCase());
      });
    },
    selectAllText() {
      if (this.search) {
        return 'Select All Search Results';
      } else {
        return 'Select All';
      }
    },
    isHeaderActive() {
      return this.filterValue.length > 0 || this.header.isSortingBy;
    },
    isBoldFont() {
      return (sortDesc) => {
        if (this.updateSort) {
          return sortDesc == this.sortDesc;
        } else {
          return (
            this.header.isSortingBy && this.header.isSortingDesc == sortDesc
          );
        }
      };
    },
  },
  data() {
    return {
      menu: false,
      search: '',
      items: [],
      selected: [],
      timeout: null,
      // make sure checkboxes in virtual list are updated to the correct state
      reload: false,
      itemsLoaded: false,
      selectAll: false,
      updateSort: false,
      sortDesc: 0,
    };
  },
  methods: {
    init() {
      this.itemsLoaded = false;
      this.items = [];
      this.selected = [];
      this.selectAll = false;
      this.search = '';
      const url = 'get-items-for-form-filter?format=json';
      const params = {
        key: this.header.value,
        filters:
          this.$store.getters.persistentSettings[this.$route.name].filters,
      };

      this.$axios.post(url, params).then((response) => {
        response.data = response.data.filter((i) => i[this.itemText] != '');
        if (this.header.isSortingBy) {
          response.data.sort((a, b) => {
            if (a[this.itemText] < b[this.itemText]) {
              return this.header.isSortingDesc ? 1 : -1;
            }
            if (a[this.itemText] > b[this.itemText]) {
              return this.header.isSortingDesc ? -1 : 1;
            }
            return 0;
          });
        }
        this.items = response.data;
        this.selected = JSON.parse(JSON.stringify(this.filterValue));
        this.selectAll = this.selected.length == this.items.length;
        this.itemsLoaded = true;
      });
    },
    toggleSelectAll(val) {
      if (val) {
        this.selected = JSON.parse(
          JSON.stringify(
            this.searchedItems.map((item) => item[this.itemValue]),
          ),
        );
      } else {
        this.selected = [];
      }
      this.reload = !this.reload;
    },
    applyFilter() {
      const payload = {};
      if (this.updateSort) {
        payload.sortDesc = this.sortDesc;
      }
      if (this.filterValue.toString() != this.selected.toString()) {
        payload.filter = this.selected;
      }
      if (JSON.stringify(payload) != '{}') {
        this.$emit('update', payload);
        this.updateSort = false;
      }
    },
    updateSorting(sortDesc) {
      this.items.sort((a, b) => {
        if (a[this.itemText].toLowerCase() < b[this.itemText].toLowerCase()) {
          return sortDesc ? 1 : -1;
        }
        if (a[this.itemText].toLowerCase() > b[this.itemText].toLowerCase()) {
          return sortDesc ? -1 : 1;
        }
        return 0;
      });
      this.sortDesc = sortDesc;
      this.updateSort = true;
      this.reload = !this.reload;
    },
    checkSelectAll() {
      if (this.selected.length == 0) {
        this.selectAll = false;
      }
      if (this.selected.length == this.items.length) {
        this.selectAll = true;
      }
    },
    resetFilter() {
      this.search = '';
      this.selected = [];
      this.selectAll = false;
      this.updateSort = false;
      this.menu = false;
      this.$emit('resetFilter');
    },
  },
  watch: {
    menu(val) {
      if (val) {
        this.init();
      } else {
        this.applyFilter();
      }
    },
  },
};
</script>

<style>
.custom-header {
  background-color: transparent !important;
}
</style>
