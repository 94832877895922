<template>
  <v-container fluid>
    <v-row :style="{ 'flex-wrap': 'nowrap' }">
      <!-- desktop filter -->
      <v-col class="shrink" v-if="formsLoaded">
        <v-row :style="topRowHeight"></v-row>
        <v-row>
          <v-col v-show="shouldDesktopFilterOpen">
            <FormInstancesFilterRevamped
              ref="filters"
              :counts="counts"
              :filters="customPageViewContents.filters"
              @confirm="applyFilters"
              @clearFilters="refresh()"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="grow" :style="{ 'overflow-x': 'auto' }">
        <v-row :style="topRowHeight">
          <!-- mobile filter -->
          <v-col v-if="isMobileFilterBreakpoint && formsLoaded">
            <v-dialog v-model="filterDialog" fullscreen>
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      class="msaBlue white--text"
                      dark
                      v-blur
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </template>
                  Filter
                </v-tooltip>
              </template>
              <FormInstancesFilterRevamped
                ref="filters"
                :counts="counts"
                :filters="customPageViewContents.filters"
                @close="filterDialog = false"
                @confirm="applyFilters"
                @clearFilters="refresh()"
              />
            </v-dialog>
          </v-col>
          <!-- desktop filter button  -->
          <v-col v-if="!isMobileFilterBreakpoint" cols="4">
            <v-btn
              class="msaBlue white--text pa-0"
              dark
              v-blur
              @click="toggleDesktopFilter()"
              v-if="formsLoaded"
            >
              <span>
                <v-icon v-if="isDesktopFilterOpen" class="mx-n2">
                  mdi-chevron-left
                </v-icon>
                <v-icon>mdi-filter</v-icon>
              </span>
            </v-btn>
          </v-col>
          <v-col align="right" v-if="isCustomPageViewContentLoaded" cols="8">
            <SaveView @save="getCustomPageViews()" v-if="formsLoaded" />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="msaBlue white--text ml-2"
                  @click="exportData()"
                  v-on="on"
                  :disabled="!formsLoaded"
                >
                  <v-icon> mdi-download </v-icon>
                </v-btn>
              </template>
              Export
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card tile>
              <v-card-title class="msaBlue white--text">
                <v-row align="center" dense>
                  <v-col class="grow">
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        v-if="customPageViewsLoaded && formsLoaded"
                      >
                        <v-menu
                          v-model="viewSelectorMenu"
                          offset-y
                          :nudge-bottom="36"
                          :nudge-left="16"
                          tile
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              {{ customPageViewContents.customPageView.name }}
                              <v-icon color="white">mdi-menu-down</v-icon>
                            </div>
                          </template>

                          <v-card tile flat>
                            <div class="font-weight-medium pl-4 pt-4">
                              Defaults
                            </div>
                            <v-list class="pt-0">
                              <v-list-item-group color="msaBlue">
                                <template
                                  v-for="view in defaultCustomPageViews"
                                >
                                  <v-list-item
                                    :key="view.id"
                                    @click="changeView(view.id)"
                                  >
                                    <v-list-item-title>
                                      {{ view.name }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </template>
                              </v-list-item-group>
                            </v-list>
                            <div
                              class="font-weight-medium pl-4 pt-4"
                              v-if="userCustomPageViews.length"
                            >
                              Custom
                            </div>
                            <v-list
                              class="pt-0"
                              v-if="userCustomPageViews.length"
                            >
                              <v-list-item-group color="msaBlue">
                                <template v-for="view in userCustomPageViews">
                                  <v-list-item
                                    :key="view.id"
                                    @click="changeView(view.id)"
                                  >
                                    <v-list-item-title>
                                      {{ view.name }}
                                    </v-list-item-title>
                                    <v-btn
                                      icon
                                      small
                                      @click="deleteView(view.id)"
                                      @click.stop
                                    >
                                      <v-icon small
                                        >mdi-close-circle-outline</v-icon
                                      >
                                    </v-btn>
                                  </v-list-item>
                                </template>
                              </v-list-item-group>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" v-else>
                        <v-skeleton-loader
                          max-width="300"
                          type="button"
                        ></v-skeleton-loader>
                      </v-col>
                      <v-col class="text-caption" v-if="countsLoaded">
                        {{ counts.totalRecordCount }} result{{
                          counts.totalRecordCount > 1 ? 's' : ''
                        }}
                      </v-col>
                      <v-col v-else>
                        <v-skeleton-loader
                          max-width="300"
                          type="text"
                        ></v-skeleton-loader>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="shrink">
                    <HeaderSelector
                      @update="updateHeaders()"
                      v-if="formsLoaded"
                    />
                  </v-col>
                  <v-col class="shrink" v-if="formsLoaded">
                    <CustomDatePicker
                      ref="datePicker"
                      :from="customPageViewContents.filters.createdFrom"
                      :to="customPageViewContents.filters.createdTo"
                      :filterIntervalId="
                        customPageViewContents.filters.createdDateIntervalId
                      "
                      @update="applyDateFilters"
                    />
                  </v-col>
                  <v-col v-else class="shrink">
                    <v-skeleton-loader type="button"></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="pa-0" v-if="formsLoaded">
                <v-data-table
                  :items="items"
                  hide-default-footer
                  hide-default-header
                  :items-per-page="20"
                  disable-sort
                  :style="{ 'overflow-x': 'auto' }"
                  mobile-breakpoint="1"
                >
                  <template v-slot:header>
                    <tr class="lightGrey">
                      <th
                        v-for="(header, i) in customPageViewContents.headers"
                        :key="i"
                        class="px-4"
                        :class="{
                          'text-center':
                            header.value == 'information' ||
                            header.value == 'status',
                          'text-left':
                            header.value != 'information' &&
                            header.value != 'status',
                        }"
                      >
                        <CompanyFormFilterableHeader
                          v-if="isHeaderFilterable(header.value)"
                          :filter-value="
                            customPageViewContents.filters[
                              getFilterKeyByHeader(header.value)
                            ]
                          "
                          :header="header"
                          :itemText="getFilterItemTextByHeader(header.value)"
                          :itemValue="getFilterItemKeyByHeader(header.value)"
                          @update="updateHeaderFilter(header, $event)"
                          @resetFilter="resetHeaderFilter(header)"
                        />
                        <span v-if="header.value == 'information'">
                          Information
                        </span>
                        <CompanyFormNonFilterableHeader
                          v-if="
                            header.value == 'createdOnMobileAt' ||
                            header.value == 'status'
                          "
                          :header="header"
                          sort-desc-first
                          @update:sort="updateSort(header.value, $event)"
                        />
                      </th>
                    </tr>
                  </template>
                  <template v-slot:body="{ items }" v-if="formsLoaded">
                    <v-slide-y-transition group tag="tbody">
                      <template v-for="(item, index) in items">
                        <tr :key="index">
                          <template
                            v-for="(
                              header, i
                            ) in customPageViewContents.headers"
                          >
                            <!-- columns that are not [created at] and [information] -->
                            <td
                              v-if="
                                header.value != 'createdOnMobileAt' &&
                                header.value != 'information' &&
                                header.value != 'status'
                              "
                              :key="i"
                            >
                              <!-- Clickable cells -->
                              <a
                                v-if="clickableHeaders.includes(header.value)"
                                :style="[
                                  twoLineTruncatedStyles,
                                  widthStyles(header.value),
                                ]"
                                @click="goToDetails(header.value, item)"
                              >
                                {{ item[header.value] }}
                              </a>
                              <!-- Non-clickable cells -->
                              <div
                                :style="[
                                  twoLineTruncatedStyles,
                                  widthStyles(header.value),
                                ]"
                                v-else
                              >
                                {{ item[header.value] }}
                              </div>
                            </td>
                            <!-- Status -->
                            <td
                              v-if="header.value == 'status'"
                              :key="i"
                              :style="{ width: '120px' }"
                            >
                              <div
                                :class="
                                  statusClass(item.formInstanceStatusId) +
                                  ' text-center'
                                "
                                :style="{ 'border-radius': '5px' }"
                              >
                                {{ item.status }}
                              </div>
                            </td>
                            <!-- Created at -->
                            <td
                              v-if="header.value == 'createdOnMobileAt'"
                              :key="i"
                              :style="{
                                width: '135px !important',
                                'min-width': '135px !important',
                              }"
                            >
                              {{ item.createdOnMobileAt | filterAsLocalDate }}
                            </td>
                            <!-- information -->
                            <td
                              v-if="header.value == 'information'"
                              :key="i"
                              :style="{ width: '150px' }"
                            >
                              <v-row
                                no-gutters
                                :style="widthStyles(header.value)"
                              >
                                <v-col>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-on="on"
                                        v-if="item.hasAudit"
                                        color="green"
                                        small
                                      >
                                        mdi-check
                                      </v-icon>
                                    </template>
                                    <span> Form has been audited. </span>
                                  </v-tooltip>
                                </v-col>
                                <v-col>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-on="on"
                                        v-if="isFormPastSync(item.updatedAt)"
                                        color="orange"
                                        small
                                      >
                                        mdi-alarm
                                      </v-icon>
                                    </template>
                                    <span>
                                      Form has not been updated in the past 8
                                      days and will not be sent to mobile
                                      devices.
                                    </span>
                                  </v-tooltip>
                                </v-col>
                                <v-col>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-on="on"
                                        v-if="item.hasUnresolvedCa"
                                        color="red"
                                        small
                                      >
                                        mdi-alert-circle
                                      </v-icon>
                                    </template>
                                    <span>
                                      Form has unresolved corrective actions.
                                    </span>
                                  </v-tooltip>
                                </v-col>
                                <v-col>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-on="on"
                                        v-if="item.hasResolvedCa"
                                        color="green"
                                        small
                                      >
                                        mdi-alert-circle
                                      </v-icon>
                                    </template>
                                    <span>
                                      Form has resolved corrective actions.
                                    </span>
                                  </v-tooltip>
                                </v-col>
                                <v-col>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-on="on"
                                        v-if="item.isPrivate"
                                        color="msaBlue"
                                        small
                                      >
                                        mdi-lock
                                      </v-icon>
                                    </template>
                                    <span> Form is private. </span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </td>
                          </template>
                        </tr>
                      </template>
                    </v-slide-y-transition>
                  </template>
                  <template v-slot:body v-else>
                    <tr>
                      <td :colspan="customPageViewContents.headers.length">
                        <v-skeleton-loader
                          type="table-tbody"
                        ></v-skeleton-loader>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <CustomPagination
              v-if="formsLoaded"
              :page="customPageViewContents.page"
              :pages="totalPages"
              @update="
                customPageViewContents.page = $event;
                formsLoaded = false;
              "
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import FormInstancesFilterRevamped from '@/components/Filters/FormInstancesFilterRevamped.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import CustomDatePicker from '@/components/CustomDatePicker.vue';
import HeaderSelector from '../components/HeaderSelector.vue';
import SaveView from '@/components/CustomPageView/SaveView.vue';
import CompanyFormFilterableHeader from '@/components/FormInstances/CompanyFormFilterableHeader.vue';
import CompanyFormNonFilterableHeader from '@/components/FormInstances/CompanyFormNonFilterableHeader.vue';
export default {
  name: 'FormsPageRevamped',
  components: {
    FormInstancesFilterRevamped,
    CustomPagination,
    CustomDatePicker,
    HeaderSelector,
    SaveView,
    CompanyFormFilterableHeader,
    CompanyFormNonFilterableHeader,
  },
  computed: {
    topRowHeight() {
      return [{ 'min-height': '60px' }, { 'max-height': '60px' }];
    },
    sortBy() {
      const header = this.customPageViewContents.headers.find(
        (header) => header.isSortingBy,
      );
      return header ? header.value : '';
    },
    sortDesc() {
      const header = this.customPageViewContents.headers.find(
        (header) => header.isSortingBy,
      );
      return header ? (header.isSortingDesc ? true : false) : false;
    },
    customPageViewContents: {
      get() {
        return new Proxy(this.$store.getters.persistentSettings.CompanyForms, {
          set: (target, key, value) => {
            this.resetItems();
            const newValue = {
              ...target,
              [key]: value,
            };
            if (key != 'page' && newValue.page != 1) {
              newValue.page = 1;
            }
            this.$store.commit('updatePersistentSettings', {
              key: 'CompanyForms',
              value: newValue,
            });
            this.load();
            return true;
          },
        });
      },
      set(value) {
        this.$store.commit('updatePersistentSettings', {
          key: 'CompanyForms',
          value: value,
        });
      },
    },
    totalPages() {
      return this.counts.totalRecordCount == 0
        ? 0
        : Math.ceil(
            this.counts.totalRecordCount /
              this.$constants.PAGINATION.DEFAULT_PERPAGE,
          );
    },
    isMobileFilterBreakpoint() {
      return this.$vuetify.breakpoint.width <= 600;
    },
    isCustomPageViewContentLoaded() {
      return JSON.stringify(this.customPageViewContents.customPageView) != '{}';
    },
    shouldDesktopFilterOpen() {
      //Ensures filter is closed on mobile view. If only using isDesktopFilterOpen, possible for filter to stay open when switching to mobileFilterBreakpoint size
      return (
        !this.isMobileFilterBreakpoint &&
        this.isDesktopFilterOpen &&
        this.isCustomPageViewContentLoaded
      );
    },
    widthStyles() {
      return (prop) => {
        return {
          'max-width': this.maxWidth[prop],
          'min-width': this.minWidth[prop],
        };
      };
    },
    defaultCustomPageViews() {
      return this.customPageViews.filter(
        (customPageView) => customPageView.userId == '',
      );
    },
    userCustomPageViews() {
      return this.customPageViews.filter(
        (customPageView) => customPageView.userId != '',
      );
    },
    statusClass() {
      return (statusId) => {
        switch (statusId) {
          case 2:
            return 'msaBlue white--text';
          case 3:
            return 'green white--text';
          case 4:
            return 'msaOrange white--text';
          case 5:
            return 'red white--text';
          default:
            return '';
        }
      };
    },
    getFilterKeyByHeader() {
      return (headerKey) => {
        switch (headerKey) {
          case 'title':
            return 'title';
          case 'users':
            return 'users';
          case 'templateName':
            return 'templateIds';
          case 'projectName':
            return 'projectIds';
          case 'createdBy':
            return 'createdByIds';
          case 'completedBy':
            return 'completedByIds';
          case 'assignedTo':
            return 'assignedToIds';
          case 'status':
            return 'status';
          case 'correctiveActionAssignedTo':
            return 'correctiveActionAssignedToIds';
          case 'tags':
            return 'tagIds';
          case 'signedOffBy':
            return 'signedOffByIds';
          case 'signedOffByContact':
            return 'signedOffByContactIds';
          default:
            return '';
        }
      };
    },
    isHeaderFilterable() {
      return (headerKey) =>
        headerKey != 'information' &&
        headerKey != 'createdOnMobileAt' &&
        headerKey != 'status';
    },
    getFilterItemTextByHeader() {
      return (headerKey) => {
        switch (headerKey) {
          case 'title':
            return 'userEnteredLabel';
          case 'users':
            return 'fullname';
          case 'templateName':
            return 'name';
          case 'projectName':
            return 'name';
          case 'createdBy':
            return 'fullname';
          case 'completedBy':
            return 'fullname';
          case 'assignedTo':
            return 'fullname';
          case 'status':
            return 'name';
          case 'correctiveActionAssignedTo':
            return 'fullname';
          case 'signedOffBy':
            return 'fullname';
          case 'signedOffByContact':
            return 'fullname';
          case 'tags':
            return 'val';
          default:
            return '';
        }
      };
    },
    getFilterItemKeyByHeader() {
      return (headerKey) => (headerKey == 'title' ? 'userEnteredLabel' : 'id');
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    isRedirected() {
      return JSON.stringify(this.$route.query) != '{}';
    },
    newView() {
      return JSON.parse(
        JSON.stringify({
          userId: this.$store.getters.user.id,
          name: 'Custom',
          id: 0,
          isDefault: 0,
        }),
      );
    },
    isFormPastSync() {
      return (updatedAt) => {
        const updatedAtDate = DateTime.fromJSDate(new Date(updatedAt));
        const now = DateTime.local();
        const diff = now.diff(updatedAtDate, 'days').toObject().days;
        return diff > this.$constants.SYNCHRONIZATION_CUTOFF_DAYS;
      };
    },
  },
  data() {
    return {
      items: [],
      formsLoaded: false,
      tags: [],
      maxWidth: {
        title: '350px',
        templateName: '300px',
        projectName: '300px',
        users: '200px',
        createdBy: '200px',
        completedBy: '200px',
        assignedTo: '200px',
        signedOffBy: '200px',
        signedOffByContact: '200px',
        correctiveActionAssignedTo: '200px',
        tags: '200px',
        createdOnMobileAt: '135px',
      },
      minWidth: {
        title: '200px',
        templateName: '100px',
        projectName: '100px',
        users: '100px',
        createdBy: '100px',
        completedBy: '100px',
        assignedTo: '100px',
        signedOffBy: '100px',
        signedOffByContact: '150px',
        correctiveActionAssignedTo: '120px',
        tags: '100px',
        createdOnMobileAt: '135px',
      },
      twoLineTruncatedStyles: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },
      counts: {
        assignedCount: 0,
        submittedCount: 0,
        pastSyncCount: 0,
        privateCount: 0,
        resolvedCaCount: 0,
        hasAuditCount: 0,
        unresolvedCaCount: 0,
        caCount: 0,
        finalizedCount: 0,
        totalCount: 0,
        reviewedCount: 0,
        hasCaCount: 0,
        notHasCaCount: 0,
        totalRecordCount: 0,
      },
      customPageViews: [],
      customPageViewsLoaded: false,
      viewSelectorMenu: false,
      clickableHeaders: [
        'title',
        'projectName',
        'users',
        'createdBy',
        'completedBy',
        'assignedTo',
        'signedOffBy',
        'correctiveActionAssignedTo',
      ],
      countsLoaded: false,
      filterDialog: false,
      isDesktopFilterOpen: true,
      isMobileFilterOpen: false,
    };
  },
  methods: {
    getItems() {
      const params = {
        page: this.customPageViewContents.page,
        headers: this.customPageViewContents.headers,
        filters: this.customPageViewContents.filters,
      };

      const url = 'get-forms-by-company?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data;
          this.formsLoaded = true;

          this.$nextTick(() => {
            if (this.$refs.datePicker) {
              this.$refs.datePicker.init();
            }
          });
        })
        .catch((error) => error);
    },
    getCounts() {
      const params = {
        headers: this.customPageViewContents.headers,
        filters: this.customPageViewContents.filters,
      };
      const url = 'get-form-counts-by-company?format=json';
      return this.$axios.post(url, params).then((response) => {
        for (const key in response.data) {
          this.counts[key] = response.data[key];
        }
        this.counts.notHasCaCount =
          this.counts.totalCount - this.counts.hasCaCount;
        this.countsLoaded = true;
      });
    },
    getDefaultCustomPageViewContents() {
      const params = {
        frontEndRouteName: this.$route.name,
      };
      const url = 'get-default-custom-page-view-contents?format=json';
      return this.$axios.post(url, params).then((response) => {
        const pageViewContents = {
          headers: response.data.headers,
          filters: response.data.filters,
          customPageView: response.data.customPageView,
          page: this.$constants.PAGINATION.DEFAULT_PAGE,
        };
        const fromAndTo = this.calculateFromAndToBasedOnFilterInterval(
          pageViewContents.filters.createdDateIntervalId,
        );
        pageViewContents.filters.createdFrom = fromAndTo.from;
        pageViewContents.filters.createdTo = fromAndTo.to;
        pageViewContents.headers.forEach((header) => {
          header.class = 'lightGrey';
        });
        this.$store.commit('updatePersistentSettings', {
          key: 'CompanyForms',
          value: pageViewContents,
        });
      });
    },
    resetItems() {
      this.items = [];
      this.counts = {
        assignedCount: 0,
        submittedCount: 0,
        pastSyncCount: 0,
        privateCount: 0,
        resolvedCaCount: 0,
        hasAuditCount: 0,
        unresolvedCaCount: 0,
        caCount: 0,
        finalizedCount: 0,
        totalCount: 0,
        reviewedCount: 0,
        hasCaCount: 0,
        notHasCaCount: 0,
        totalRecordCount: 0,
      };
    },
    load() {
      if (this.$route.name == 'CompanyForms') {
        this.resetItems();
        this.getCounts()
          .then(() => {
            this.getItems();
          })
          .catch((error) => error);
      }
    },
    applyFilters(filters) {
      this.$store.commit('updatePersistentSettings', {
        key: 'CompanyForms',
        value: {
          headers: this.customPageViewContents.headers,
          filters,
          customPageView: this.newView,
          page: this.$constants.PAGINATION.DEFAULT_PAGE,
        },
      });
      if (!this.customPageViews.some((view) => view.id == 0)) {
        this.customPageViews.push(this.customPageViewContents.customPageView);
      }
      this.formsLoaded = false;
      this.countsLoaded = false;
      this.load();
    },
    getCustomPageViews() {
      const params = {
        frontEndRouteName: this.$route.name,
      };
      const url = 'get-custom-page-views?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.customPageViews = response.data;
          if (this.customPageViewContents.customPageView.id == 0) {
            this.customPageViews.push(
              this.customPageViewContents.customPageView,
            );
          }
          this.customPageViewsLoaded = true;
        })
        .catch((error) => error);
    },
    updateHeaders() {
      if (!this.customPageViews.some((view) => view.id == 0)) {
        this.customPageViews.push(this.customPageViewContents.customPageView);
      }
      this.customPageViewContents.page =
        this.$constants.PAGINATION.DEFAULT_PAGE;
      this.formsLoaded = false;
    },
    changeView(viewId) {
      if (viewId > 0) {
        this.formsLoaded = false;
        this.countsLoaded = false;
        this.viewSelectorMenu = false;
        const params = {
          id: viewId,
          frontEndRouteName: this.$route.name,
        };
        const url = 'change-custom-page-view?format=json';
        this.$axios.post(url, params).then((response) => {
          this.$store.commit('updatePersistentSettings', {
            key: 'CompanyForms',
            value: {
              headers: response.data.headers,
              filters: response.data.filters,
              customPageView: response.data.customPageView,
              page: this.$constants.PAGINATION.DEFAULT_PAGE,
            },
          });
          this.load();
        });
      }
    },
    refresh() {
      this.$store.commit('updatePersistentSettings', {
        key: 'CompanyForms',
        value: {
          headers: [],
          filters: {},
          customPageView: {},
          page: this.$constants.PAGINATION.DEFAULT_PAGE,
        },
      });
      this.$router
        .push({ name: 'CompanyForms' })
        // if push fails, page is going to refreshed
        .catch(() => this.$router.go());
    },
    goToDetails(key, item) {
      if (key == 'projectName') {
        this.$store.commit('updateSelectedProject', {
          name: item.projectName,
          id: item.projectId,
        });
        this.$router.push({
          name: 'ProjectDetails',
          params: {
            id: item.projectId,
          },
        });
      } else if (key == 'title') {
        this.$store.commit('updateSelectedFormInstance', {
          name: item.title,
        });
        this.$router.push({
          name: 'CompanyFormDetails',
          params: {
            id: item.id,
          },
        });
      } else {
        const idKey = key == 'users' ? 'userId' : key + 'Id';
        this.$router.push({
          name: 'UserDetails',
          params: {
            id: item[idKey],
          },
        });
      }
    },
    calculateFromAndToBasedOnFilterInterval(filterIntervalId) {
      const result = { from: '', to: '' };
      switch (filterIntervalId) {
        case this.$constants.FILTER_INTERVALS.ALL_TIME:
          break;
        case this.$constants.FILTER_INTERVALS.CUSTOM:
          break;
        case this.$constants.FILTER_INTERVALS.TODAY:
          result.from = DateTime.local().toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.YESTERDAY:
          result.from = DateTime.local()
            .minus({ days: 1 })
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local()
            .minus({ days: 1 })
            .toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.THIS_WEEK:
          result.from = DateTime.local().startOf('week').toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_WEEK:
          result.from = DateTime.local()
            .minus({ weeks: 1 })
            .startOf('week')
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local()
            .minus({ weeks: 1 })
            .endOf('week')
            .toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_7_DAYS:
          result.from = DateTime.local()
            .minus({ days: 7 })
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_14_DAYS:
          result.from = DateTime.local()
            .minus({ days: 14 })
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.THIS_MONTH:
          result.from = DateTime.local()
            .startOf('month')
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_MONTH:
          result.from = DateTime.local()
            .minus({ months: 1 })
            .startOf('month')
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local()
            .minus({ months: 1 })
            .endOf('month')
            .toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_30_DAYS:
          result.from = DateTime.local()
            .minus({ days: 30 })
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.THIS_QUARTER:
          result.from = DateTime.local()
            .startOf('quarter')
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_QUARTER:
          result.from = DateTime.local()
            .minus({ quarters: 1 })
            .startOf('quarter')
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local()
            .minus({ quarters: 1 })
            .endOf('quarter')
            .toFormat('yyyy-LL-dd');
          break;
        default:
          break;
      }
      return result;
    },
    exportData() {
      const params = {
        headers: this.customPageViewContents.headers,
        filters: this.customPageViewContents.filters,
        loaderText: 'Exporting data...',
      };
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      const url = 'export-forms-by-company?format=json';
      this.$axios.post(url, params, options).then((response) => {
        this.$helpers.downloadFile(response.data, 'Forms-export.xlsx');
      });
    },
    deleteView(viewId) {
      if (viewId == 0) {
        this.refresh();
      } else {
        const confirm = () => {
          const url = 'delete-custom-page-view?format=json';
          const params = {
            id: viewId,
          };
          this.$axios.post(url, params).then(() => {
            this.refresh();
          });
        };
        this.$root.showMessage(
          'Warning',
          'Are you sure you want to delete this saved view?',
          () => confirm(),
          () => false,
          'Confirm',
          'Cancel',
        );
      }
    },
    applyDateFilters(event) {
      this.applyFilters({
        ...this.customPageViewContents.filters,
        createdFrom:
          event.filterIntervalId == this.$constants.FILTER_INTERVALS.CUSTOM
            ? event.from
            : '',
        createdTo:
          event.filterIntervalId == this.$constants.FILTER_INTERVALS.CUSTOM
            ? event.to
            : '',
        createdDateIntervalId: event.filterIntervalId,
      });
    },
    updateSort(key, sortDesc) {
      this.formsLoaded = false;
      const headers = JSON.parse(
        JSON.stringify(this.customPageViewContents.headers),
      );
      headers.forEach((header) => {
        header.isSortingBy = 0;
        header.isSortingDesc = 0;
        if (key == header.value) {
          header.isSortingBy = 1;
          header.isSortingDesc = sortDesc;
        }
      });
      this.$store.commit('updatePersistentSettings', {
        key: 'CompanyForms',
        value: {
          headers,
          filters: this.customPageViewContents.filters,
          customPageView: this.newView,
          page: this.$constants.PAGINATION.DEFAULT_PAGE,
        },
      });
      if (!this.customPageViews.some((view) => view.id == 0)) {
        this.customPageViews.push(this.customPageViewContents.customPageView);
      }
      this.load();
    },
    updateHeaderFilter(header, payload) {
      let filters = null;
      let headers = null;
      if (payload.filter != undefined) {
        filters = {
          ...this.customPageViewContents.filters,
          [this.getFilterKeyByHeader(header.value)]: payload.filter,
        };
      }
      if (payload.sortDesc != undefined) {
        headers = JSON.parse(
          JSON.stringify(this.customPageViewContents.headers),
        );
        headers.forEach((h) => {
          h.isSortingBy = 0;
          h.isSortingDesc = 0;
          if (header.value == h.value) {
            h.isSortingBy = 1;
            h.isSortingDesc = payload.sortDesc;
          }
        });
      }

      this.$store.commit('updatePersistentSettings', {
        key: 'CompanyForms',
        value: {
          headers: headers ?? this.customPageViewContents.headers,
          filters: filters ?? this.customPageViewContents.filters,
          customPageView: this.newView,
          page: this.$constants.PAGINATION.DEFAULT_PAGE,
        },
      });
      if (!this.customPageViews.some((view) => view.id == 0)) {
        this.customPageViews.push(this.customPageViewContents.customPageView);
      }

      this.formsLoaded = false;
      this.countsLoaded = false;
      this.load();
    },
    resetHeaderFilter(header) {
      const filters = {
        ...this.customPageViewContents.filters,
        [this.getFilterKeyByHeader(header.value)]:
          this.$constants.COMPANY_FORM_FILTERS[
            this.getFilterKeyByHeader(header.value)
          ],
      };

      const headers = this.customPageViewContents.headers.map((h) => {
        h.isSortingBy = 0;
        h.isSortingDesc = 0;
        if (h.value == 'createdOnMobileAt') {
          h.isSortingBy = 1;
          h.isSortingDesc = 1;
        }
        return h;
      });

      this.$store.commit('updatePersistentSettings', {
        key: 'CompanyForms',
        value: {
          headers: headers,
          filters: filters,
          customPageView: this.newView,
          page: this.$constants.PAGINATION.DEFAULT_PAGE,
        },
      });
      if (!this.customPageViews.some((view) => view.id == 0)) {
        this.customPageViews.push(this.customPageViewContents.customPageView);
      }

      this.formsLoaded = false;
      this.countsLoaded = false;
      this.load();
    },
    getRouteQueries() {
      const url = 'get-custom-page-view-content-from-route-queries?format=json';
      const params = {
        frontEndRouteName: this.$route.name,
        routeQueries: this.$route.query,
      };
      return this.$axios.post(url, params).then((response) => response.data);
    },
    toggleDesktopFilter() {
      this.isDesktopFilterOpen = !this.isDesktopFilterOpen;
    },
  },
  mounted() {
    if (this.isRedirected) {
      this.getRouteQueries()
        .then((payload) => {
          this.$store.commit('updatePersistentSettings', {
            key: 'CompanyForms',
            value: {
              headers: payload.headers,
              filters: payload.filters,
              customPageView: this.newView,
              page: this.$constants.PAGINATION.DEFAULT_PAGE,
            },
          });
          this.load();
        })
        .catch((error) => error);
    } else {
      if (!this.isCustomPageViewContentLoaded) {
        this.getDefaultCustomPageViewContents()
          .then(() => {
            this.load();
          })
          .catch((error) => error);
      } else {
        this.load();
      }
    }

    this.getCustomPageViews();
  },
};
</script>

<style></style>
